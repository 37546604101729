import React from 'react'
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout/layout";
import cx from "classnames";
import { LINKS } from "../constants/links";
import {
  Typography as MuiTypography,
  Grid, CircularProgress,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Pagination from "@material-ui/lab/Pagination";

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 'auto',
    zIndex: 100,
    maxWidth: 1060,
    width: '100%',
    margin: 'auto',
    padding: '0 50px',
    marginBottom: 100,
  },
  articlesContainer: {
    marginTop: 64,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  articleContainer: {
    cursor: "pointer",
  },
  previewImage: {
    height: 'auto',
    width: '100%',
    zIndex: 2,
    transition: 'display 1s ease 2s',
    backgroundColor: 'white',
  },
  previewImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    aspectRatio: 2.4,
    overflow: 'hidden',
    marginBottom: 18,
    borderRadius: 10,
    willChange: 'transform',
  },
  spinnerImage: {
    color: "#f15a29",
    position: 'absolute',
  },
  articleTitle: {
    color: "#f15a29",
    textDecoration: 'none',
    marginBottom: 5,
    marginTop: 0,
  },
  articleCreated: {
    fontSize: '.725rem',
    letterSpacing: 1,
    marginBottom: 20,
  },
  shortDescription: {
    fontSize: '.96rem',
    marginBottom: 20,
  },
  articleContent: {
    fontSize: '1rem',
  },
  articleLink: {
    color: "#f15a29",
    textDecoration: 'none',
  },
}));

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $id: String!, $strapiId: Int!) {
    strapiTag(id: {eq: $id}) {
      title
    }
    allStrapiArticle(
      filter: {tags: {elemMatch: {id: {eq: $strapiId}}}}
      sort: {fields: created_at, order: DESC},
      skip: $skip,
      limit: $limit
    ) {
      edges {
        node {
          title
          slug
          shortDescription
          image
          id
          created_at
          content
          author {
            username
          }
        }
      }
    }
    allStrapiCaseStudiesArticles(
      filter: {tags: {elemMatch: {id: {eq: $strapiId}}}}
      sort: {fields: created_at, order: DESC},
      skip: $skip,
      limit: $limit
    ) {
      edges {
        node {
          Title
          slug
          shortDescription
          image
          id
          created_at
          content
        }
      }
    }
  }
`;


const UserTemplate = ({ data, pageContext }) => {
  const classes = useStyles();
  const allStrapiArticle = data.allStrapiArticle.edges.map((item) => {
    item.node.source = LINKS.blogArticle;
    return item;
  });
  const allStrapiCaseStudiesArticles = data.allStrapiCaseStudiesArticles.edges.map((item) => {
    item.node.source = LINKS.caseStudiesArticle;
    item.node.title = item.node.Title;
    return item;
  });

  const posts = [...allStrapiArticle, ...allStrapiCaseStudiesArticles,];

  const changePage = (event, page) => {
    navigate(`${LINKS.tags}/${pageContext.title}${page !== 1 ? `pages/${page}/` : ''}`);
  };

  const openArticle = event => {
    navigate(`${event.currentTarget.dataset.source}/${(!!event.currentTarget.dataset.slug
      ? event.currentTarget.dataset.slug
      : event.currentTarget.dataset.title)
      .replaceAll(' ', '-').toLowerCase()}/`);
  }

  return <Layout SeoProps={{
    title: 'Blog',
    description: 'blog',
  }}>
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h2">
          {data.strapiTag.title}
        </Typography>
        {
          pageContext.numPages > 1 &&
          <Pagination
            count={pageContext.numPages}
            onChange={changePage}
            defaultPage={pageContext.currentPage}
            shape="rounded"
            hidePrevButton
            hideNextButton
            color="primary"
          />
        }
      </div>
      <Grid container spacing={2} className={classes.articlesContainer}>
        {posts.map(( _post) => {
          const post = _post.node;
          //date
          const date = new Date(post.created_at).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'UTC',
          })

          //image
          let imageFixed = post.image;

          if (!imageFixed) {
            const regex = /!\[.*?\]\(.*?\)/gm;
            const found = post.content.match(regex);
            if (found && found.length) {
              imageFixed = found[0].split('(')[1].slice(0, -1);
            }
          }


          //short description
          let shortDescription = post.shortDescription
          // ToDo: clear markdown syntax
          if (!post.shortDescription) {
            const removeHeader = post.content.split('#');
            const text = removeHeader[0].length > 100 ?
              removeHeader[0] :
              removeHeader[1] && removeHeader[1].length > 100 ?
                removeHeader[1] :
                (removeHeader[2] && removeHeader[2]) || removeHeader[0]
            shortDescription = text.slice(0, 300).trim() + '...';
          }

          return <Grid item md={4} xs={12} sm={6}
                       key={post.id}
                       data-id={post.id}
                       data-slug={post.slug}
                       data-title={post.title}
                       data-source={post.source}
                       className={classes.articleContainer}
                       onClick={openArticle}
          >
            {
              imageFixed && <div className={classes.previewImageContainer}>
                <CircularProgress className={classes.spinnerImage}/>
                <img src={imageFixed} alt="preview image" className={classes.previewImage} />
              </div>
            }
            <h2 className={classes.articleTitle}>
              {post.title}
            </h2>
            <Typography className={cx(classes.typography, classes.articleCreated)}>
              {date}
            </Typography>
            <Typography className={cx(classes.typography, classes.shortDescription)}>
              {shortDescription}
            </Typography>
            <Typography className={classes.articleTitle}>
              Continue reading...
            </Typography>
          </Grid>
        })}
      </Grid>
    </div>
  </Layout>
}

export default UserTemplate
